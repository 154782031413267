import loadable from "@loadable/component";

import { TestPage } from "feat/test/TestPage";
import { InfoPage } from "feat/info/InfoPage";
import { PageNotFound } from "common/PageNotFound";

const SettingsPage = loadable(
  () => import("feat/asuno/components/SettingsPage")
);
const LineSettingsPage = loadable(
  () =>
    import(
      "feat/substations/components/SubstationPage/LinesSettings/LineSettingsPage"
    )
);
const ObjectsPage = loadable(
  () => import("feat/objects/components/ObjectsPage")
);
const IndicationsPage = loadable(
  () => import("feat/indications/components/IndicationsPage")
);
const ReportingPage = loadable(
  () => import("feat/reporting/components/ReportingPage")
);
const BurningHoursPage = loadable(
  () => import("feat/burningHours/components/BurningHoursPage")
);
const SubstationsPage = loadable(
  () => import("feat/substations/components/pages/SubstationsPage")
);
const SchedulePage = loadable(
  () => import("feat/schedule/components/pages/SchedulePage")
);
const ScheduleListPage = loadable(
  () => import("feat/schedule/components/pages/ScheduleListPage")
);
const MapPage = loadable(() => import("feat/map/components/MapPage"));
const ChartPage = loadable(() => import("feat/chart/components/ChartPage"));
const UsersListPage = loadable(
  () => import("feat/users/components/UsersListTable/UsersListPage")
);
const UserCreatePage = loadable(
  () => import("feat/users/components/UserCreate/UserCreatePage")
);
const AccessCreatePage = loadable(
  () => import("feat/users/components/AccessCreate/AccessCreatePage")
);
const LoginForm = loadable(() => import("feat/auth/components/LoginForm"));
const UserProfilePage = loadable(
  () => import("feat/users/components/Profile/UserProfilePage")
);
const RolePage = loadable(
  () => import("feat/users/components/RolePage/RolePage")
);
const ConsumptionForecastPage = loadable(
  () =>
    import(
      "feat/reporting/components/ConsumptionForecast/ConsumptionForecastPage"
    )
);
const ReportsPage = loadable(
  () => import("feat/reporting/components/ReportsPage/ReportsPage")
);
const DevicesPage = loadable(
  () => import("feat/devices/components/DevicesPage/DevicesPage")
);
const DevicesHistoryPage = loadable(
  () =>
    import(
      "feat/devices/components/Photorelays/DevicesHistory/DevicesHistoryPage"
    )
);
const SubstationPage = loadable(
  () => import("feat/substations/components/pages/SubstationPage")
);
const ModemTestingPage = loadable(
  () => import("feat/devices/components/ModemTesingPage/ModemTestingPage")
);
const ModemsHistoryPage = loadable(
  () => import("feat/devices/components/ModemsHistory/ModemsHistoryPage")
);
const ModemReplacePage = loadable(
  () => import("feat/devices/components/ModemReplace/ModemReplacePage")
);
const EmeterReplacePage = loadable(
  () => import("feat/devices/components/EmeterReplace/EmeterReplacePage")
);
const AppendPage = loadable(
  () => import("feat/devices/components/AppendPage/AppendPage")
);

const DocsPage = loadable(
  () => import("feat/docs/components/DocsPage/DocsPage")
);
const ChangeLogPage = loadable(
  () => import("feat/docs/components/ChangeLogPage/ChangeLogPage")
);
const HelpPage = loadable(
  () => import("feat/docs/components/HelpPage/HelpPage")
);
const LogsPage = loadable(() => import("feat/logs/components/LogsPage"));
const DirectoriesPage = loadable(
  () => import("feat/admin/components/Directories/DirectoriesPage")
);
const СonfigurationsPage = loadable(
  () => import("feat/admin/components/Configurations/СonfigurationsPage")
);
const ConfigFormPage = loadable(
  () => import("feat/admin/components/Configurations/ConfigFormPage")
);
const OrderSettingsPage = loadable(
  () => import("feat/asuno/components/OrderSettings/OrderSettingsPage")
);
const ConsumptionReportSettingsPage = loadable(
  () =>
    import(
      "feat/asuno/components/ConsumptionReportSettings/ConsumptionReportSettingsPage"
    )
);
const LightningControlPage = loadable(
  () => import("feat/control/components/LightningControlPage")
);
const LampsListPage = loadable(
  () => import("feat/lamps/components/pages/LampsListPage")
);
const LampPage = loadable(() => import("feat/lamps/components/pages/LampPage"));
const TestEmeterPage = loadable(
  () => import("feat/service/components/TestEmeter/TestEmeterPage")
);

export {
  SettingsPage,
  LineSettingsPage,
  ObjectsPage,
  IndicationsPage,
  ReportingPage,
  BurningHoursPage,
  SubstationsPage,
  SchedulePage,
  ScheduleListPage,
  MapPage,
  ChartPage,
  UsersListPage,
  UserCreatePage,
  AccessCreatePage,
  LoginForm,
  UserProfilePage,
  RolePage,
  ConsumptionForecastPage,
  ReportsPage,
  DevicesPage,
  DevicesHistoryPage,
  SubstationPage,
  ModemTestingPage,
  ModemsHistoryPage,
  ModemReplacePage,
  EmeterReplacePage,
  AppendPage,
  DocsPage,
  ChangeLogPage,
  HelpPage,
  LogsPage,
  DirectoriesPage,
  СonfigurationsPage,
  ConfigFormPage,
  OrderSettingsPage,
  ConsumptionReportSettingsPage,
  LightningControlPage,
  LampsListPage,
  LampPage,
  TestEmeterPage,
  TestPage,
  InfoPage,
  PageNotFound,
};
