import { RootState } from "app/store";
import moment from "moment";
import Chart from "chart.js";

// types
import { IApi } from "lib/api/requests";
import {
  ISettingsCheckbox,
  ISettingsRoles,
  ISettingsAsuno,
  ILineMode,
  ILocale,
} from "lib/enums";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";

export type ThunkAPI = {
  extra: {
    api: IApi;
  };
  rejectValue: string | unknown;
  state: RootState;
};

export type Maybe<T> = T | null;

export type IError = string | null;

export type ILightingLineGroup = {
  active: boolean;
  group_name: string;
  group_id: number;
  parent_group_id: number;
  child_groups: number[];
  lines: ILines[];
  photorelays: IPhotoRelayLine[];
  locality_name: string;
  timezone: number;
  lat: number;
  lng: number;
  customer: Record<number, string>;
  services: Record<number, string>;
  lines_manual: number;
  lines_offline: number;
  flags: {
    show_control_panel: boolean;
    use_groups: boolean;
    use_photorelay: boolean;
    use_new_schedules_system: boolean;
  };
};

export type ILightingLineGroupWithChild = Omit<
  ILightingLineGroup,
  "child_groups"
> & {
  child_groups: ILightingLineGroup[];
};

export type ILines = {
  is_linked: boolean;
  name: string;
  address: string;
  comment: string;
  emeters: {
    em: IModemOrEmeter;
    rel?: IModemOrEmeter;
  };
  modems: Array<IModemOrEmeter>;
  lat: number;
  lng: number;
  in_maintenance: boolean;
  props: {
    cf_curr_trans: number;
    em_snum: string;
  };
  groups: number[];
  id: string;
  hamsl: number;
  state: ILineState;
  rate: number[];
  notify_params?: INotifyParams;
  tariff: ITariff;
  flags: {
    has_control: boolean;
    has_current_params: boolean;
    has_readings: boolean;
  };
  lamp_groups?: Array<ILampsGroup>;
};

export type IReportingParams = {
  period: moment.Moment[];
  line_ids: Record<string, string[]>;
  forecast?: string;
  tariff?: ITariff | "default";
};

export type IReportForecastBody = {
  against: string;
  to: string;
  month: string;
  lines: string[];
};

export type ILightningLineConsForecast = {
  time: number;
  lines: IForecastLines[];
  summary: IForecastLines;
};

export type IForecastLines = {
  name: string;
  cons: number;
  base_cons: number;
  base_pwr: number;
  pwr: number;
};

export type ILinkState = {
  modem: IModemOrEmeter;
  val: number;
  ts: number;
};

export enum IConnectionError {
  TCP = "tcp",
}

export type IConnectionState = {
  modem: IModemOrEmeter;
  error?: IConnectionError;
  val: boolean;
  ts: number;
};

export type ILineState = {
  ctl_mode: {
    val: ILineMode;
    ts: number;
  };
  relay: {
    val: boolean;
    ts: number;
  };
  contactor: object;
  el_params: {
    val: {
      eap: number[];
      ts: number;
    };
    ts: number;
  };
  lst_activ_ts: {
    ts: number;
  };
  is_online: Array<IConnectionState>;
  line_id: string;
  incident_phase_1: boolean;
  incident_phase_2: boolean;
  incident_phase_3: boolean;
  link: Array<ILinkState>;
  emeter_link: boolean;
  lamp_groups?: {
    color: Record<string, RgbwColor & { ts: number }>;
    relay: Record<string, { val: boolean; ts: number }>;
    mode: Record<
      string,
      {
        mode: Maybe<ILineMode>;
        ts: number;
        schedule_id: Maybe<number>;
        schedule_name: Maybe<string>;
      }
    >;
  };
};

export type IBurningHoursRecord = Record<
  string,
  {
    scheduled_time: number;
    actual_time: number;
    group_records?: Record<
      string,
      { scheduled_time: number; actual_time: number }
    >;
  }
>;

export type IBurningHoursRecordsTable = {
  date: string;
  scheduled_time: number;
  actual_time: number;
  group_records?: Record<
    string,
    { scheduled_time: number; actual_time: number }
  >;
};

export type ILinesBurningHoursReport = {
  ll_id: string;
  name: string;
  scheduled_total: number;
  actual_total: number;
  records: IBurningHoursRecord;
  group_total?: Record<
    string,
    { scheduled_total: number; actual_total: number }
  >;
};

export type ILineFilter = {
  sorter: string;
  term: string;
  onlyByName?: boolean;
};

export type IGroupScheduleParams = {
  group_id?: number;
  from: number | string;
  to?: number | string;
};

export type IGroupSchedule = {
  id?: number;
  sched_id?: number;
  group_id: number;
  ts_st: string;
  ts_end: string;
  state: string;
  state_param: string;
  color?: Maybe<RgbwColor>;
  dimm?: Maybe<number>;
};

export type ICalendarModals = {
  period: boolean;
  day: boolean;
  file: boolean;
  sun: boolean;
  edit: boolean | number;
};

export type IChartDataItem = {
  x: number;
  y: number;
};

export type IChartDataSheet = {
  eap_s: IChartDataItem[];
  eap_1: IChartDataItem[];
  eap_2: IChartDataItem[];
  pwr_actv_s: IChartDataItem[];
  pwr_actv_1: IChartDataItem[];
  pwr_actv_2: IChartDataItem[];
  pwr_actv_3: IChartDataItem[];
  u1: IChartDataItem[];
  u2: IChartDataItem[];
  u3: IChartDataItem[];
  i1: IChartDataItem[];
  i2: IChartDataItem[];
  i3: IChartDataItem[];
  is: IChartDataItem[];
  time: number[];
  gap_time: number;
};

export type IChartData = {
  line_id: string;
  rdts: number[][];
  gap_time: number;
  contact_pwr_actv: {
    contract_pwr_actv_1: Maybe<number>;
    contract_pwr_actv_2: Maybe<number>;
    contract_pwr_actv_3: Maybe<number>;
    contract_pwr_actv_s: number;
  };
};

export type IRole = {
  system?: boolean;
  role_id?: number;
  scopes: string[];
  role_name: string;
  group_id: number;
};

export type IUserRole = {
  role_id: number;
  role_name?: string;
  group_id: number;
};

export type IUser = {
  active: boolean;
  name: string;
  surname: string;
  id: number;
  roles: IUserRole[];
  username: string;
  email: string;
  global_admin: boolean;
  phone: string;
  has_subs: boolean;
  lang: ILocale;
  last_action: string;
  valid_until: string;
  service_worker: boolean;
};

export type ICreateUser = {
  name: string;
  surname: string;
  roles: IUserRole[];
  username: string;
  email: string;
  password: string;
  phone?: string;
  service_worker?: boolean;
  valid_until: Maybe<string>;
};

export type IUpdateUser = Partial<IUser> & {
  id: number;
  password?: string;
  group_id?: number;
};

export type IAccessMap = {
  id: string;
  name: string;
  view: boolean;
  isViewable: boolean;
  edit?: boolean;
  control?: boolean;
  isEditable?: boolean;
  isControlable?: boolean;
};

export type IUserLogin = {
  email: string;
  password: string;
};

export type ILineRequest = {
  from: string;
  to: string;
  lines: string[];
  forecast?: string;
  point_quantity?: number;
  tariff?: ITariff | "default";
};

export type IConsumptionSliceReqest = {
  slices: ILineConsumptionSlice[];
  sum_eap: number[];
};

export type ILineConsumptionSlice = {
  line_id: string;
  from: number;
  to: number;
  eap_begin: number[];
  eap_end: number[];
  eap_diff: number[];
  forecast?: number;
};

export type IChangeStateWS = {
  ver: number;
  proto: string;
  ts: number;
  sid: number;
  objType: string;
  objId: string;
  notifyImmediately?: boolean;
  changedStates: {
    contactor: boolean;
    ctl_mode: string;
    photorelayState: string;
    lastActivityTs: number;
    color?: RgbwColor;
    states?: Array<{ group_num?: number | null; light_on: boolean }>;
    group_num?: number;
    schedule_id?: number;
    schedule_name?: string;
    schedule_counters?: Record<string, number>;
  };
  message?: string;
  eventSource: string;
};

export type ILineCommand = {
  ver: number;
  ts: number;
  sid: number;

  proto: string;
  cmd?: "relayDisable" | "relayEnable" | "lampGroupSetColor" | "dimm";
  cmdParams?: object;
  objects: { type: string; id: string }[];
  group_num?: string | null;
  group_id?: string | null;
  schedule_id?: number | null;
  mode?: ILineMode;
  color?: RgbwColor;
  relay?: boolean;
};

export type IEditLightingLineGroup = {
  group_name: string;
  group_id?: number;
  parent_group_id?: Maybe<number>;
  child_groups?: number[];
  lines: string[];
  locality_name: string;
  timezone: number;
  lat: number;
  lng: number;
  active?: boolean;
  use_photorelay: boolean;
  customer?: Maybe<number>;
  config_id?: number | null;
};

export type IAccessSettingsMap = {
  checkbox: ISettingsCheckbox;
  edit: boolean;
  roles: ISettingsRoles;
  asuno: ISettingsAsuno;
};

export type IPhotoRelayState = "dark" | "light" | "broken" | "offline";

export type IPhotoRelay = {
  name: string;
  id: string;
  ts: number;
  state: IPhotoRelayState;
  endp_uri: string;
};

export type IPhotoRelayLine = {
  name: string;
  lat: number;
  lng: number;
  in_maintenance: true;
  props: {
    em_snum: string;
  };
  groups: number[];
  id: string;
  state: IPhotoRelayState;
  rate: number[];
  ts: number;
  endp_uri: string;
};

export type IPhotoRelayHistory = {
  id: string;
  name: string;
  ts: string;
  state: IPhotoRelayState;
  endp_uri: string;
};

export type IModemHistory = {
  signal_strength: number;
  ts: string;
};

export type IModemHistoryData = Array<{
  quantity: number;
  signals: Array<IModemHistory>;
}>;

export interface INotifyParams {
  ll_id: string;

  description_1: string;
  description_2: string;
  description_3: string;
  contract_pwr_actv_1: number;
  contract_pwr_actv_2: number;
  contract_pwr_actv_3: number;
  contract_pwr_actv_s: number;

  /**
   * Активная мощность min, сумма
   */
  pwr_actv_s_min: number;
  /**
   * Активная мощность max, сумма
   */
  pwr_actv_s_max: number;
  /**
   * Активная мощность min, фаза А
   */
  pwr_actv_1_min: number;
  /**
   * Активная мощность max, фаза А
   */
  pwr_actv_1_max: number;
  /**
   * Активная мощность min, фаза B
   */
  pwr_actv_2_min: number;
  /**
   * Активная мощность max, фаза B
   */
  pwr_actv_2_max: number;
  /**
   * Активная мощность min, фаза C
   */
  pwr_actv_3_min: number;
  /**
   * Активная мощность max, фаза C
   */
  pwr_actv_3_max: number;
  /**
   * Напряжение min, фаза А
   */
  u_1_min: number;
  /**
   * Напряжение max, фаза A
   */
  u_1_max: number;
  /**
   * Напряжение min, фаза B
   */
  u_2_min: number;
  /**
   * Напряжение max, фаза B
   */
  u_2_max: number;
  /**
   * Напряжение min, фаза C
   */
  u_3_min: number;
  /**
   * Напряжение max, фаза C
   */
  u_3_max: number;
  /**
   * Коэффициент мощности min, фаза А
   */
  cf_1_min: number;
  /**
   * Коэффициент мощности min, фаза B
   */
  cf_2_min: number;
  /**
   * Коэффициент мощности min, фаза C
   */
  cf_3_min: number;
  /**
   * Частота min
   */
  fr_min: number;
  /**
   * Частота max
   */
  fr_max: number;
}

export type IChartProps = {
  chartDataMap: IChartDataSheet;
  showTabs?: boolean;
  setChartRefs: React.Dispatch<React.SetStateAction<IChartRefs>>;
  chartRefs: IChartRefs;
  showStatistics?: boolean;
};

export type IDeviceHestoryFilter = [Maybe<moment.Moment>, Maybe<moment.Moment>];

export type IChartRef = Chart.Chart<
  keyof Chart.ChartTypeRegistry,
  (number | Chart.ScatterDataPoint | Chart.BubbleDataPoint | null)[],
  unknown
> | null;

export type IChartRefs = Record<string, ChartJSOrUndefined<"line"> | null>;

export type ILineHistory = {
  text: string;
  date: string;
  id: number;
  success: boolean;
  detail: Record<string, string>;
};

export type ILinesUpdate = {
  name: string;
  address?: string;
  comment?: string;
  lat?: number;
  lng?: number;
  in_maintenance?: boolean;
  props?: {
    cf_curr_trans: number;
    em_snum: string;
  };
  groups?: number[];
  hamsl?: number;
  notify_params?: INotifyParams;
  config_id?: number;
};

export type IModemOrEmeter = {
  type: string;
  id: string;
};

export type ISunSchedule = {
  group_id?: number;
  ts_end: string;
  ts_st: string;
};

export type IScheduleRecord = {
  group_id?: number;
  ts_end: string;
  ts_st: string;
  state?: string;
  color?: Maybe<RgbwColor>;
  dimm?: Maybe<number>;
};

export type ILog = {
  action: string;
  text: string;
  ts: string;
  user_id: number;
  user_name: string;
  group_id: number;
  email: string;
};

export type ILogAnswer = {
  logs: ILog[];
  quantity: number;
};

export type ILogFilter = {
  user: Maybe<number>;
  period: [Maybe<moment.Moment>, Maybe<moment.Moment>];
  asuno: Maybe<string>;
  page: number;
  action: Maybe<string>;
};

export type ILogActions = {
  id: string;
  action: string;
};

export type ILogUsers = {
  id: string;
  name: string;
};

export type IChartCtx = {
  dataset: {
    backgroundColor: string;
    borderColor: string;
    label: string;
    fill: boolean;
    spanGaps: boolean;
  };
};

export type IScheduleFileCheckingError = {
  filename: string;
  invalid_rows: (string[] | number[])[];
  invalid_rows_count: number;
  total_rows_amount: number;
  valid_file_format: boolean;
  valid_sheet_format: boolean;
};

export type IRoleType = "system" | "local";

export type ISettingsType = {
  checkbox: ISettingsCheckbox;
  edit: boolean;
  roles: ISettingsRoles;
  asuno: ISettingsAsuno;
};

export type ICustomer = {
  id: number;
  name: string;
};

export type IService = {
  id: number;
  name: string;
};

export type IDevice = {
  date: string;
  pole_id: number;
  sub_id: string;
  lat: number;
  lng: number;
  lamps?: Array<{
    lamp: string;
    barcode: number;
    lamp_id: number;
    photo: string | null;
  }>;
};

export type IConfiguration = {
  id: number;
  name: string;
  emeter_is_control_device: boolean; // чекбокс
  readings_source: string; // если 2 устр то выбор (счетчик или умное реле)
  current_params_source: string; // если 2 устр то выбор (счетчик или умное реле)
  modem_1_type: string;
  modem_2_type: string | null;
  emeter_type: string;
  control_device_type: string | null; // если выбрано 2 счетчика - умное реле
};

export type IConnectionFields = {
  emeter_id: boolean;
  control_device_id: boolean;
  modem_1_id: boolean;
  modem_2_id: boolean;
};

export type IConnectionInput = {
  connection_data: Record<string, string>;
  new_config_id?: number;
};

export type IReplaceDevicesInput = {
  replacement_data: Record<string, string>;
  new_config_id?: number;
};

export type IReplaceModemInput = {
  old_modem_id: string;
  new_modem_id: string;
};

export type IReplaceDeviceInput = {
  emeter_id?: string;
  control_device_id?: string;
};

export enum ITariff {
  ONEZONE = "onezone",
  TWOZONE = "twozone",
  THREEZONE = "threezone",
  HOURLY = "hourly",
}

export type ILamp = {
  id: string;
  group_num: string;
  lamp_type: string;
  color: RgbwColor;
  dim: Maybe<number>;
  title: string;
  position: [number, number];
  barcode: string;
};

export type ILampBase = {
  id: string;
  barcode: string;
  model: string;
  ll_name: string;
};

export type ILampsGroup = {
  id: string;
  group_num: string;
  group_name: string;
  lamps: string[];
  color: RgbwColor;
  dim: Maybe<number>;
  ll_id: string;
  relay: boolean;
  mode: string;
  schedule_id: Maybe<number>;
  schedule_name: Maybe<string>;
  use_color: boolean;
  use_dim: boolean;
  name?: string;
  in_maintenance?: boolean;
  color_scheme: IColorScheme;
};

export enum IScheduleType {
  SIMPLE = "simple",
  DIMMING = "dimming",
  RGBW = "rgbw",
}

export enum IColorScheme {
  RGB = "rgb",
  RGBW = "rgbw",
}

export type ISchedule = {
  id: number;
  ll_group_id: number;
  name: string;
  type: IScheduleType;
  lamp_groups_count: number;
  is_cyclic: boolean;
};

export type ICreateScheduleArgs = Pick<
  ISchedule,
  "name" | "type" | "is_cyclic"
>;

export type ILineArg = Omit<ILineCommand, "ver" | "ts" | "sid">;

export type RgbwColor = {
  r: number;
  g: number;
  b: number;
  w: number;
};

export type IDeviceInfoProp = {
  model: string;
  id: string;
  gates: {
    devId: string;
    devType: string;
  }[];
};

export type IDeviceInfo = {
  group_id: number;
  group_name: string;
  ll_id: string;
  ll_name: string;
  device: IDeviceInfoProp;
};

export type IEmeterElParams = {
  current: number;
  power: number;
  voltage: number;
};

export type IEmeterState = {
  is_online: boolean;
  is_testing: boolean;
  emeter_info: {
    emeter_num: string;
    phases_num: number;
    has_relay: boolean;
    el_params_a: IEmeterElParams;
    el_params_b: IEmeterElParams;
    el_params_c: IEmeterElParams;
    readings: number[];
    errors: number[];
  } | null;
  fail: boolean;
};
