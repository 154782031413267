import { Switch, Route } from "react-router-dom";

//components
import { PrivateRoute } from "common/PrivateRoute";
import { withMenuLayout } from "common/MenuLayout";

import {
  SettingsPage,
  LineSettingsPage,
  ObjectsPage,
  IndicationsPage,
  ReportingPage,
  BurningHoursPage,
  SubstationsPage,
  SchedulePage,
  ScheduleListPage,
  MapPage,
  ChartPage,
  UsersListPage,
  UserCreatePage,
  AccessCreatePage,
  LoginForm,
  UserProfilePage,
  RolePage,
  ConsumptionForecastPage,
  ReportsPage,
  DevicesPage,
  DevicesHistoryPage,
  SubstationPage,
  ModemTestingPage,
  ModemsHistoryPage,
  ModemReplacePage,
  EmeterReplacePage,
  AppendPage,
  DocsPage,
  ChangeLogPage,
  HelpPage,
  LogsPage,
  TestPage,
  InfoPage,
  DirectoriesPage,
  PageNotFound,
  СonfigurationsPage,
  ConfigFormPage,
  OrderSettingsPage,
  ConsumptionReportSettingsPage,
  LightningControlPage,
  LampsListPage,
  LampPage,
  TestEmeterPage,
} from "./pages";

export const Routes: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute
        path="/substations"
        exact
        component={withMenuLayout(SubstationsPage)}
      />
      <PrivateRoute
        exact
        path="/substations/:id"
        component={withMenuLayout(SubstationPage)}
      />
      <PrivateRoute
        path="/substations/:id/edit"
        component={withMenuLayout(SubstationPage)}
      />
      <PrivateRoute
        path="/substations/:id/test"
        component={withMenuLayout(ModemTestingPage)}
      />
      <PrivateRoute
        path="/substations/:id/history/:index?"
        component={withMenuLayout(ModemsHistoryPage)}
      />
      <PrivateRoute
        path="/substations/:id/replace/:modemId"
        component={withMenuLayout(ModemReplacePage)}
      />
      <PrivateRoute
        path="/substations/:id/replace-emeter/:deviceId/:deviceType?"
        component={withMenuLayout(EmeterReplacePage)}
      />
      <PrivateRoute
        path="/substations/:id/append"
        component={withMenuLayout(AppendPage)}
      />
      <PrivateRoute
        path="/indications"
        component={withMenuLayout(IndicationsPage)}
      />
      <PrivateRoute
        path="/consumption_forecast"
        component={withMenuLayout(ConsumptionForecastPage)}
      />
      <PrivateRoute
        path="/consumption_fact"
        component={withMenuLayout(ReportingPage)}
      />
      <PrivateRoute
        path="/burning_hours"
        component={withMenuLayout(BurningHoursPage)}
      />

      <PrivateRoute path="/reports" component={withMenuLayout(ReportsPage)} />
      <PrivateRoute
        path="/schedule/:scheduleId?"
        component={withMenuLayout(SchedulePage)}
      />
      <PrivateRoute
        exact
        path="/schedules"
        component={withMenuLayout(ScheduleListPage)}
      />
      <PrivateRoute path="/map" component={withMenuLayout(MapPage)} />
      <PrivateRoute path="/chart" component={withMenuLayout(ChartPage)} />
      <PrivateRoute
        exact
        path="/users"
        component={withMenuLayout(UsersListPage)}
      />
      <PrivateRoute
        path="/users/create-user"
        component={withMenuLayout(UserCreatePage)}
      />
      <PrivateRoute
        path="/users/create-access"
        component={withMenuLayout(AccessCreatePage)}
      />
      <PrivateRoute
        path="/users/profile/:id?"
        component={withMenuLayout(UserProfilePage)}
      />
      <PrivateRoute
        path="/users/role/:id?"
        component={withMenuLayout(RolePage)}
      />
      <PrivateRoute
        exact
        path="/asuno"
        component={withMenuLayout(SettingsPage)}
      />
      <PrivateRoute
        path="/asuno/order-settings"
        component={withMenuLayout(OrderSettingsPage)}
      />
      <PrivateRoute
        path="/asuno/consumption-report-settings"
        component={withMenuLayout(ConsumptionReportSettingsPage)}
      />
      <PrivateRoute
        path="/line/:id?"
        component={withMenuLayout(LineSettingsPage)}
      />

      <PrivateRoute
        exact
        path="/devices"
        component={withMenuLayout(DevicesPage)}
      />
      <PrivateRoute
        path="/devices/:id?"
        component={withMenuLayout(DevicesHistoryPage)}
      />
      <PrivateRoute
        path="/control"
        component={withMenuLayout(LightningControlPage)}
      />
      <PrivateRoute
        exact
        path="/lamps"
        component={withMenuLayout(LampsListPage)}
      />
      <PrivateRoute
        exact
        path="/lamps/:lampId"
        component={withMenuLayout(LampPage)}
      />

      <PrivateRoute
        exact
        path="/admin/directories"
        component={withMenuLayout(DirectoriesPage)}
      />

      <PrivateRoute
        exact
        path="/admin/configurations"
        component={withMenuLayout(СonfigurationsPage)}
      />
      <PrivateRoute
        path="/admin/configurations/:id"
        component={withMenuLayout(ConfigFormPage)}
      />

      <PrivateRoute
        exact
        path="/admin/users"
        component={withMenuLayout(UsersListPage)}
      />
      <PrivateRoute
        path="/admin/users/profile/:id?"
        component={withMenuLayout(UserProfilePage)}
      />
      <PrivateRoute
        path="/admin/users/role/:id?"
        component={withMenuLayout(RolePage)}
      />
      <PrivateRoute
        path="/admin/users/create-user"
        component={withMenuLayout(UserCreatePage)}
      />
      <PrivateRoute path="/admin/logs" component={withMenuLayout(LogsPage)} />
      <PrivateRoute path="/logs" component={withMenuLayout(LogsPage)} />
      <PrivateRoute path="/test" component={withMenuLayout(TestPage)} />

      <PrivateRoute
        path="/service"
        component={withMenuLayout(TestEmeterPage)}
      />

      <Route path="/docs" component={withMenuLayout(DocsPage)} />
      <Route path="/changelog" component={withMenuLayout(ChangeLogPage)} />
      <Route path="/help" component={withMenuLayout(HelpPage)} />
      <Route path="/info" component={InfoPage} />
      <Route path="/login" component={withMenuLayout(LoginForm)} />

      <PrivateRoute exact path="/" component={withMenuLayout(ObjectsPage)} />

      <Route component={PageNotFound} />
    </Switch>
  );
};
