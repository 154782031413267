import { useState } from "react";
import { useAppDispatch } from "app/hooks";
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// components
import { Menu } from "antd";
import { SlidersOutlined } from "@ant-design/icons";

// utils
import { mobileMenuSlice } from "common/reducers";

const { setMobileMenuOpen } = mobileMenuSlice.actions;

export const ServiceMenu: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [selectedKeys, setSelectedKeys] = useState([location.pathname]);

  const onKeyClick = ({ key }: { key: string }) => {
    dispatch(setMobileMenuOpen(false));
    setSelectedKeys([key]);
  };

  return (
    <Menu
      theme="light"
      mode="inline"
      triggerSubMenuAction="click"
      onClick={onKeyClick}
      selectedKeys={selectedKeys}
    >
      <Menu.Item key="/service" icon={<SlidersOutlined />}>
        <Link to="/service">
          <FormattedMessage id="admin.test-emeter" />
        </Link>
      </Menu.Item>
    </Menu>
  );
};
